.citations {
  padding: 0 var(--mod-2-multiplier);
}
.citations .container {
  flex-direction: row;
}
.container {
  display: flex;
}
.citationOutputContainer {
  flex-basis: 0;
  position: relative;
  flex-grow: 1;
  margin: var(--mod-1-multiplier) 0 var(--mod-1-multiplier)
    var(--mod-1-multiplier);
}
@media (--screen-mobile-max) {
  .citations {
    padding: 0;
    min-height: calc(100vh - 55px);
  }
  .citations .container {
    flex-direction: column;
  }
  .citationOutputContainer {
    flex-basis: auto;
    background: var(--background-default);
    min-height: calc(100vh - 300px);
    margin: 0;
  }

  .citationOpenDisplay {
    display: none;
  }

  .citationClosedDisplay {
    display: block;
  }
}
